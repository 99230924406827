import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet } from '../utils/axiosAsync/async';

function TinyUrlRedirect() {
    const { tinyUrl } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFullUrl = async () => {
            const [err, result] = await axiosGet(`/v2/patient/${tinyUrl}`);

            if (err) {
                console.error('Error fetching URL:', err);
                navigate('/not-found', { replace: true });
                return;
            }

            if (!result) { // Handle the case where result is null or undefined
                console.error("No result from server");
                navigate('/not-found', { replace: true });
                return;
            }


            if (result.status >= 200 && result.status < 300) {
                const fullUrl = result?.data;
                navigate(fullUrl, { replace: true });

            } else if (result.status === 302 || result.status === 301) {

                const fullUrl = result.headers.location;

                if (!fullUrl) {
                    console.error("Server did not return a redirect URL.");
                    navigate('/not-found', { replace: true });
                } else {
                    navigate(fullUrl, { replace: true });
                }

            } else {
                console.error('Unexpected status code:', result.status);
                navigate('/not-found', { replace: true });
            }
        };

        fetchFullUrl();
    }, [tinyUrl, navigate]);

    return <div>Redirecting...</div>;
}

export default TinyUrlRedirect;