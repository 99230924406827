export const sidebarConfigs = {
    collapsedWidth: "70px",
    expandedWidth: "220px",
    defaultPadding: "10px",
    colors: {
        defaults: {
            bgClassName: "bg-[#062E55]",
            activeNavClassName: "bg-[#1874DF]",
            hoverNavClassName: "hover:bg-[#547296]",
            textColor: "text-white"
        }
    }
};

export const gridConfigs = {
    colors: {
        defaults: {
            linkFormatterClassName: "text-[#0E5EBC]",
            rowBgClassName: "bg-white"
        }
    }
}

export const textConfigs = {
    colors: {
        defaults: {
            textColorClassName: "text-[#0467DA]",
            textLinkColorClassNames: "text-[#0463D1]",
            hoverTextColorClassName: "hover:text-[#1874DF]"
        }
    }
}

export const tabConfigs = {
    colors: {
        defaults: {
            inactiveTabBgClassName: "bg-[#E5EFFB]",
            inactiveTabBorderClassName: "border-[#CCD7E4]"
        }
    }
}

export const dropdownConfigs = {
    colors: {
        defaults: {
            bgClassName: "bg-[#EFF6FF]",
            borderClassName: "border-[#CCD7E4] border border-solid",
            activeBorderClassName: "border-[#0463D1] border border-solid"
        }
    }
}

export const buttonConfigs = {
    colors: {
        defaults: {
            secondary: "bg-transparent text-[#0E5EBC] border border-[#0E5EBC]",
            primary: "bg-[#0E5EBC] text-white border border-[#0E5EBC]",
            danger: "bg-[#F50303] text-white border border-[#F50303]",
            disabled: "bg-[#C7C7C7] text-white border border-[#C7C7C7]"
        }
    }
}

export const encounterConfigs = {
    colors: {
        defaults: {
            ccm: "bg-ccm-gradient",
            rpm: "bg-rpm-gradient",
            ict: "bg-ict-gradient",
            assessment: "bg-asmt-gradient",
            ccmText: "text-[#C06B51]",
            rpmText: "text-[#059281]",
            ictText: "text-[#403ABF]",
            assessmentText: "text-[#059281]"
        }
    }
}

export const timelineConfigs = {
    colors: {
        defaults: {
            timelineBgClassName: "bg-[#C3C3C3]",
            timelineHoverBgClassName: "bg-[#0E5EBC]",
            timelineBorderClassName: "border-[#E8E8E8] border-2 border-solid",
            timelineHoverBorderClassName: "border-[#0E5EBC] border-2 border-solid",
            timelineCircleBgClassName: "bg-[#B2B1B1]",
            timelineXAxisLabelClassName: "text-[#000000]",
            timelineXAxisLabelHoverClassName: "text-[#0E5EBC]",
            timelineXAxisLabelWeight: "font-semibold"
        }
    }
}

export const iconConfigs = {
    colors: {
        defaults: {
            bgGreen: "bg-[#E0FFDB]",
            iconGreen: "text-[#13CAA9]",
            bgBlue: "bg-[#D3E7FF]",
            iconBlue: "text-[#373780]",
            bgPurple: "bg-[#F0E3FA]",
            iconPurple: "text-[#9351C7]",
            bgBlue2: 'bg-[#DCEBFF]',
            iconBlue2: 'text-[#4479B5]'
        }
    }
}

export const indicatorConfigs = {
    colors: {
        defaults: {
            bgGreen: "bg-[#F0FFEE]",
            textGreen: "text-[#129A0F]"
        }
    }
}

export const dividerConfigs = {
    colors: {
        defaults: {
            borderColor: "border-[#D8D8D8]"
        }
    }
}

export const tooltipConfigs = {
    colors: {
        defaults: {
            bgColorCode: "#002E64",
            bgColorClassName: "bg-[#002E64]",
            textColorClassName: "text-white"
        }
    },
    barConstantClassnames: "w-[170px] p-3 font-medium text-sm text-wrap rounded shadow-tooltip",
    pieConstantClassnames: "w-max p-3 z-[1050] font-medium text-sm text-wrap rounded shadow-tooltip"
}

export const badgeConfigs = {
    colors: {
        defaults: {
            red: "text-[#F91C1C] bg-[#FFE3E3]",
            grey: "text-[#fff] bg-[#3e3c41]",
            transparent: "bg-transparent"
        }
    }
}

export const toastConfigs = {
    defaults: {
        position: "top-right",
        duration: 10000,
        success: {
            icon: <i className="clinicus-checkmark-outline self-start mt-[7px] ml-1" />,
            style: { background: "#18B945", color: "white" }
        },
        error: {
            icon: <i className="clinicus-exclamation-outline" />,
            style: { background: "red", color: "white" }
        }
    }
}
